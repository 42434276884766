// Generated by Framer (5c93d09)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, SVG, Text, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {KFDlpKJbi: {hover: true}};

const cycleOrder = ["KFDlpKJbi"];

const serializationHash = "framer-k5GVg"

const variantClassNames = {KFDlpKJbi: "framer-v-1sixu7y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, I1t3ycPa8: title ?? props.I1t3ycPa8 ?? "Digital Marketing"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, I1t3ycPa8, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "KFDlpKJbi", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (gestureVariant === "KFDlpKJbi-hover") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-k5GVg", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1sixu7y", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"KFDlpKJbi"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"KFDlpKJbi-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Text __fromCanvasComponent alignment={"left"} className={"framer-k6q9xd"} data-framer-name={"Digital Marketing"} fonts={["CUSTOM;Altone Medium"]} layoutDependency={layoutDependency} layoutId={"Dpw0R1NAo"} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>Digital Marketing</span><br></span></span>"} style={{"--framer-font-family": "\"Altone Medium\", \"Altone Medium Placeholder\", sans-serif", "--framer-font-size": "20px", "--framer-font-style": "normal", "--framer-font-weight": 400, "--framer-letter-spacing": "0em", "--framer-line-height": "24px", "--framer-text-alignment": "left", "--framer-text-color": "var(--token-d59b9de7-914a-416b-b35c-0bc1e3fd3641, rgb(255, 255, 255))", "--framer-text-decoration": "none", "--framer-text-transform": "capitalize"}} text={I1t3ycPa8} variants={{"KFDlpKJbi-hover": {"--framer-text-color": "var(--token-e938dcc6-2a0e-4c12-9846-4efab72d34e8, rgb(228, 2, 70))"}}} verticalAlignment={"center"} withExternalLayout/>{isDisplayed() && (<SVG className={"framer-uruj0l"} data-framer-name={"Line 2"} fill={"rgba(0,0,0,1)"} intrinsicHeight={23} intrinsicWidth={7} layoutDependency={layoutDependency} layoutId={"zP1pxmPy2"} svg={"<svg width=\"7\" height=\"23\" viewBox=\"-2 -2 7 23\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<line x1=\"1.25\" y1=\"1.25\" x2=\"1.25\" y2=\"17.75\" stroke=\"white\" stroke-width=\"2.5\" stroke-linecap=\"round\"/>\n</svg>\n"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-k5GVg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-k5GVg .framer-1xf27o9 { display: block; }", ".framer-k5GVg .framer-1sixu7y { height: 26px; overflow: hidden; position: relative; width: 185px; }", ".framer-k5GVg .framer-k6q9xd { bottom: -2px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }", ".framer-k5GVg .framer-uruj0l { flex: none; height: 23px; left: 0px; position: absolute; top: calc(51.85185185185187% - 23px / 2); width: 7px; }", ".framer-k5GVg .framer-v-1sixu7y .framer-1sixu7y { cursor: pointer; }", ".framer-k5GVg.framer-v-1sixu7y.hover .framer-k6q9xd { left: unset; right: -3px; width: 173px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 26
 * @framerIntrinsicWidth 185
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"YouiTc9J7":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"I1t3ycPa8":"title"}
 * @framerImmutableVariables true
 */
const FramerX75B19GHK: React.ComponentType<Props> = withCSS(Component, css, "framer-k5GVg") as typeof Component;
export default FramerX75B19GHK;

FramerX75B19GHK.displayName = "Archive / Digital Marketing tab";

FramerX75B19GHK.defaultProps = {height: 26, width: 185};

addPropertyControls(FramerX75B19GHK, {I1t3ycPa8: {defaultValue: "Digital Marketing", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerX75B19GHK, [{family: "Altone Medium", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/X75B19GHK:default", url: "https://framerusercontent.com/assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf"}, url: "https://framerusercontent.com/assets/cSj6ZY9zQbC02MkWJ6N0nfdOzrE.ttf"}])